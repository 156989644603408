import React from 'react'
import Navbar from '../Navbar'
import Layout8 from '../Subjects/Layout8'
import Main_footer from '../Footer'
import Backtotop from '../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import FAQ from '../Subjects/FAQ'
import Blogs from '../Subjects/Blogs'

const Boards_page = () => {
  return (
    <div className='animate__animated animate__fadeIn'>
      <div className="board-div pb-5">
        <Navbar value={"board"} color={"#8236C8"}/>
        <div className='d-flex align-items-center container1'>
        <div className="row m-0 container mx-auto mt-md-4">
            <div className="col-lg-6">
            <div className="h-100 d-flex flex-column justify-content-between">
                <div>
                <p className='online-text fw-bolder text-white m-0'>Give the best</p>
                <p className='online-text fw-bolder text-white m-0' style={{lineHeight:'50px'}}>Education to </p>

                <div className='mt-5 pt-4'>
                    <p className='text-white'>Meet and communicate with the best people to run projects, events or other activities in a more effective and fun way</p>
                    <button className='text-white border-0 px-5 py-2 shadow' style={{backgroundColor:'#DB2A6B',fontSize:'14px',borderRadius:'10px 10px 10px 0px'}}>Get Started</button>
                </div>
                </div>

                <div className='row m-0'>
                   <div className="col-md-4">
                   <div className='d-flex align-items-center'>
                        <img src={require('../../images/icon.png')} alt="" />
                        <div className='ms-2'>
                        <p className='text-white fw-meidum m-0'>Build</p>
                        <p className='text-white fw-meidum m-0'>Community</p>
                        </div>
                    </div>
                   </div>

                   <div className="col-md-4">
                   <div className='d-flex align-items-center'>
                        <img src={require('../../images/icon (1).png')} alt="" />
                        <div className='ms-2'>
                        <p className='text-white fw-meidum m-0'>Build</p>
                        <p className='text-white fw-meidum m-0'>Community</p>
                        </div>
                    </div>
                   </div>

                   <div className="col-md-4">
                   <div className='d-flex align-items-center'>
                        <img src={require('../../images/icon (2).png')} alt="" />
                        <div className='ms-2'>
                        <p className='text-white fw-meidum m-0'>Build</p>
                        <p className='text-white fw-meidum m-0'>Community</p>
                        </div>
                    </div>
                   </div>
                </div>
            </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
                <img src={require('../../images/content.png')} style={{width:'90%'}} alt="" />
            </div>
        </div>
        </div>
      </div>

      <div className="board-subdiv2">
      <div className="row m-0 container mx-auto py-5">
        <p className='fs-1 fw-bold text-center'>Teach students worldwide</p>

        <div className='mt-4'>
          <p className='text-center mb-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni in animi culpa voluptas iste facere obcaecati atque doloribus ad non architecto, optio quam velit et dolor beatae consectetur illum, tempore aut quis blanditiis vero tempora officia. Illum animi, adipisci vero quasi deserunt, doloribus fugit ipsum minus deleniti beatae obcaecati neque blanditiis nulla voluptatem repellendus maxime officia. Labore dolore totam, odit exercitationem aliquid placeat autem. Quis enim laudantium necessitatibus tempora exercitationem perferendis nisi magnam? Animi ab libero numquam harum nisi hic at, corporis explicabo totam optio assumenda repellendus? Unde suscipit fuga vitae ullam ad? Fuga rerum sit porro, voluptas ex sapiente?</p>
          <p className='text-center mb-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni in animi culpa voluptas iste facere obcaecati atque doloribus ad non architecto, optio quam velit et dolor beatae consectetur illum, tempore aut quis blanditiis vero tempora officia. Illum animi, adipisci vero quasi deserunt, doloribus fugit ipsum minus deleniti beatae obcaecati neque blanditiis nulla voluptatem repellendus maxime officia. Labore dolore totam, odit exercitationem aliquid placeat autem. Quis enim laudantium necessitatibus tempora exercitationem perferendis nisi magnam? Animi ab libero numquam harum nisi hic at, corporis explicabo totam optio assumenda repellendus? Unde suscipit fuga vitae ullam ad? Fuga rerum sit porro, voluptas ex sapiente?</p>
          <p className='text-center mb-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni in animi culpa voluptas iste facere obcaecati atque doloribus ad non architecto, optio quam velit et dolor beatae consectetur illum, tempore aut quis blanditiis vero tempora officia. Illum animi, adipisci vero quasi deserunt, doloribus fugit ipsum minus deleniti beatae obcaecati neque blanditiis nulla voluptatem repellendus maxime officia. Labore dolore totam, odit exercitationem aliquid placeat autem. Quis enim laudantium necessitatibus tempora exercitationem perferendis nisi magnam? Animi ab libero numquam harum nisi hic at, corporis explicabo totam optio assumenda repellendus? Unde suscipit fuga vitae ullam ad? Fuga rerum sit porro, voluptas ex sapiente?</p>

        </div>
      </div>
      </div>

      {/* Layout2 */}
      <div className="row m-0 container mx-auto py-5 my-5">
        <div className="col-lg-5 text-end" style={{position:'relative'}}>
            <img src={require('../../images/Rectangle 48.png')} style={{width:'70%'}} alt="" />

            <div className='bg-white d-flex align-items-center justify-content-between shadow p-3 board-small-div1' style={{borderRadius:'10px',position:'absolute'}}>
                <div className='d-flex align-items-center'>
                <img src={require('../../images/Ellipse 33.png')} width={50} alt="" />
                <div className='ms-2'>
                    <p className='fw-medium m-0 text-start'>Madhavan</p>
                    <p className='m-0 text-secondary mt-1' style={{fontSize:'13px'}}>Expert Saving Money</p>
                </div>
                </div>
                <img src={require('../../images/Group 109.png')} alt="" />
            </div>

            <div className='bg-white shadow p-3 board-small-div2' style={{borderRadius:'10px',position:'absolute'}}>
                <p className='text-secondary m-0 mb-1 text-start' style={{fontSize:'13px'}}>Total Income</p>
                <p className='d-flex align-items-center m-0 justify-content-between'><span className='fw-bold'>$2000</span>
                <img src={require('../../images/bar-chart-2 1.png')} alt="" /></p>
            </div>

            <div className='bg-white shadow p-3 board-small-div3' style={{borderRadius:'10px',position:'absolute'}}>
                <p className='d-flex align-items-center m-0'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#8236C8" class="bi bi-check-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg> <span className='ms-3'>Money Transfer Succesfull</span></p>
            </div>

        </div>  
        <div className="col-lg-5 ms-auto">
            <p className='fs-1 fw-bolder'>What Benifit Will You Get</p>

            <div className='mt-5'>
                <p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#8236C8" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg> <span className='ms-3 fw-medium'>Free Consulting With Experet Saving Money</span></p>
<p className='d-flex align-items-center mt-4'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#8236C8" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg> <span className='ms-3 fw-medium'>Online Banking</span></p>
<p className='d-flex align-items-center mt-4'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#8236C8" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg> <span className='ms-3 fw-medium'>Investment Report Every Month</span></p>
<p className='d-flex align-items-center mt-4'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#8236C8" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg> <span className='ms-3 fw-medium'>Saving Money For The Future</span></p>
<p className='d-flex align-items-center mt-4'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#8236C8" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg> <span className='ms-3 fw-medium'>Online Transection</span></p>
            </div>
        </div>
      </div>

      {/* Layout3 */}
      <div className='board-layout3'>
        <div className="row m-0 container mx-auto">
            <div className="col-lg-5 text-end">
                <img src={require('../../images/Rectangle 236.png')} alt="" />
            </div>
            <div className="col-lg-4 pt-5">
                <p className='fs-1 fw-bolder text-white'>learn
                Without Limits</p>
                <p className='text-white' style={{fontSize:'14px'}}>WooCommerce is developer friendly, too. Built with a REST API, WooCommerce is scalable and can integrate with virtually any service. Design a complex store from scratch, extend a store for a client, or simply add a single product to a WordPress site—your store, your way.</p>
            
                <button className='btn text-white px-4 rounded-pill mt-4 py-2' style={{backgroundColor:'#8236C8',fontSize:'14px'}}>Read the Documentation</button>
            </div>
        </div>

        <div className="row m-0 container mx-auto justify-content-center">
            <div className="col-lg-7 pt-5">
               <div className='board-subdiv'>
                 <p className='fs-1 fw-bolder text-white'>learn 
                 Everything U want</p>
                <p className='text-white' style={{fontSize:'14px'}}>WooCommerce is developer friendly, too. Built with a REST API, WooCommerce is scalable and can integrate with virtually any service. Design a complex store from scratch, extend a store for a client, or simply add a single product to a WordPress site—your store, your way.</p>
            
                <button className='btn text-white px-4 rounded-pill mt-4 py-2' style={{backgroundColor:'#8236C8',fontSize:'14px'}}>Read the Documentation</button>
               </div>
            </div>
            <div className="col-lg-5 text-start">
                <img src={require('../../images/Rectangle 233.png')} alt="" />
            </div>
        </div>
      </div>

      <Blogs/>
      <Layout8/>
      {/* FAQ */}
      <FAQ/>
      <Main_footer/>
      <Backtotop/>
      <Scrolltotop/>
    </div>
  )
}

export default Boards_page
