import React from 'react'
import { useNavigate } from 'react-router-dom'
import Work_svg from './works-svgs/Work_svg'
import Work_svg1 from './works-svgs/Work_svg1'
import Work_svg2 from './works-svgs/Work_svg2'
import Work_svg3 from './works-svgs/Work_svg3'
import Work_svg4 from './works-svgs/Work_svg4'
import Work_svg5 from './works-svgs/Work_svg5'
import Blogs from '../Subjects/Blogs'
import Main_footer from '../Footer'
import FAQ from '../Subjects/FAQ'
import Layout8 from '../Subjects/Layout8'
import Navbar from '../Navbar'
import Backtotop from '../Backtotop'
import Scrolltotop from '../../Scrolltotop'

const How_it_works = () => {
    const navigate=useNavigate()

  return (
    <div className='animate__animated animate__fadeIn'>
      <div className="work-div">
        <Navbar value={"work"} color={'#7200D2'}/>
        <div className="row container m-0 mx-auto h-100 mt-4">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
            <div className="">
                <p className='work-text text-white'>Reshaping Careers</p>
                <p className='work-text text-white'>With Hassle-Free</p>
                <p className='work-text text-white'>Tution Services</p>
                <p className='text-white mt-5'>Sed in cursus sem. Proin malesuada neque quis dictum dignissim. Maecenas vitae felis diam. Praesent pellentesque suscipit commodo. Fusce in pretium dolor. Proin eget nibh accumsan, placerat erat sit amet, pellentesque nisi.</p>

                <div className='mt-5'>
                    <button className='btn btn-light rounded-pill fw-bold px-4 py-2'>Become a Student</button>
                    <button className='btn text-white border rounded-pill fw-bold px-4 py-2 ms-3' onClick={()=>{
                        navigate('/add_tutor')
                    }}>Become a Teacher</button>
                </div>
            </div>

            <div className="row m-0 mt-5">
                <div className="col-md-4">
                    <div>
                        <p className='fs-2 fw-bolder text-white m-0'>523K+</p>
                        <p className='text-white'>Total Customer</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div>
                        <p className='fs-2 fw-bolder text-white m-0'>200K+</p>
                        <p className='text-white'>Real time Customer</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div>
                        <p className='fs-2 fw-bolder text-white m-0'>100%</p>
                        <p className='text-white'>Customer Satisfaction</p>
                    </div>
                </div>
            </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-end">
                <img src={require('../../images/WORK/Image Placeholder.png')} style={{width:'78%'}} alt="" />
            </div>
        </div>
      </div>
      <div className="work-div2">
        <img src={require('../../images/WORK/Vector 32 (1).png')} style={{width:'100%',height:'90%'}} alt="" />
      </div>

{/* Layout2 */}
      <div className="row m-0 container mx-auto py-5 my-4">
        <p className='fs-1 fw-bolder text-center'>Why Perfect Tutor?</p>
        <p className=' text-center mb-5 pb-4'>Sed in cursus sem. Proin malesuada neque quis dictum dignissim. Maecenas vitae felis diam. Praesent pellentesque suscipit commodo. Fusce in pretium dolor. Proin eget nibh accumsan, placerat erat sit amet, pellentesque nisi.</p>


        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>English</p>
                <p className='mt-4 text-center'>Aenean varius ultricies dapibus. Vivamus et tortor et libero cursus facilisis et a tortor. Etiam eleifend eleifend</p>

            </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg1/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>Tamil</p>
                <p className='mt-4 text-center'>Aenean varius ultricies dapibus. Vivamus et tortor et libero cursus facilisis et a tortor. Etiam eleifend eleifend</p>

            </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg2/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>German</p>
                <p className='mt-4 text-center'>Aenean varius ultricies dapibus. Vivamus et tortor et libero cursus facilisis et a tortor. Etiam eleifend eleifend</p>

            </div>
        </div>
        
      </div>
    
{/* Layout3 */}
<div className="work-div3 py-5" style={{backgroundColor:'#EDF1FD'}}>
    <div className="row m-0 py-4 container mx-auto justify-content-center">
        <div className="col-lg-5">
            <p className='fs-2 fw-bolder'>How We work ?</p>
            <p className='m-0' style={{color:'#4F4F4F'}}>For Student,</p>
            <p className='m-0 mt-3' style={{color:'#4F4F4F'}}>Sed in cursus sem. Proin malesuada neque quis dictum dignissim. Maecenas vitae felis diam. Praesent pellentesque suscipit commodo. Fusce in pretium dolor.</p>
            <p className='m-0 mt-3' style={{color:'#4F4F4F'}}>Sed in cursus sem. Proin malesuada neque quis dictum dignissim. Maecenas vitae felis diam. Praesent pellentesque suscipit commodo. Fusce in pretium dolor.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque facere debitis fugit nesciunt numquam. Voluptates voluptate iusto explicabo deserunt eius id sint nemo, quisquam aspernatur ducimus et? Accusamus, officia. Quasi?
            </p>

            <div className='mt-5 mb-4 pb-1'>
                <p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Seamless Collaboration Better Responses.</span></p>
<p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Automate workflow with our no-code rules.</span></p>
<p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Drive sales with apps and integrations.</span></p>
<p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Seamless Collaboration Better Responses.</span></p>
<p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Automate workflow with our no-code rules.</span></p>
            </div>

            <button className='mt-4 btn text-white px-4 py-2 rounded-pill d-flex align-items-center justify-content-center' style={{backgroundColor:'#184AC9',fontSize:'14px'}}>Learn More <span className='ms-1'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg></span></button>

            
        </div>
        <div className="col-lg-6 text-end">
            <img src={require('../../images/WORK/Image Placeholder (1).png')} style={{width:'80%'}} alt="" />
        </div>
    </div>
</div>

{/* Layout4 */}
<div className="work-div3 py-5" style={{backgroundColor:'#F9F9F9'}}>
    <div className="row m-0 py-4 container mx-auto justify-content-center">
        <div className="col-lg-6 text-start">
            <img src={require('../../images/WORK/Image Placeholder (2).png')} style={{width:'80%'}} alt="" />
        </div>
        <div className="col-lg-5">
            <p className='fs-2 fw-bolder'>How We work ?</p>
            <p className='m-0' style={{color:'#4F4F4F'}}>For Schools or Colleges,</p>
            <p className='m-0 mt-3' style={{color:'#4F4F4F'}}>Sed in cursus sem. Proin malesuada neque quis dictum dignissim. Maecenas vitae felis diam. Praesent pellentesque suscipit commodo. Fusce in pretium dolor.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque facere debitis fugit nesciunt numquam. Voluptates voluptate iusto explicabo deserunt eius id sint nemo, quisquam aspernatur ducimus et? Accusamus, officia. Quasi?
            </p>

            <div className='mt-5 mb-4 pb-1'>
                <p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Seamless Collaboration Better Responses.</span></p>
<p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Automate workflow with our no-code rules.</span></p>
<p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Drive sales with apps and integrations.</span></p>
<p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Seamless Collaboration Better Responses.</span></p>
<p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Automate workflow with our no-code rules.</span></p>
            </div>

            <button className='mt-4 btn text-white px-4 py-2 rounded-pill d-flex align-items-center justify-content-center' style={{backgroundColor:'#184AC9',fontSize:'14px'}}>Learn More <span className='ms-1'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg></span></button>

            
        </div>
    </div>
</div>

{/* Layout4 */}
<div className='work-layout4' style={{paddingTop:'60px',paddingBottom:'70px'}}>
            
            <div className="row m-0 mt-4 container mx-auto">
                <div className="col-md-6 p-4 ps-md-0 d-flex align-items-center">
                    <div className=''>
                       <p className='fw-bolder fs-1 m-0'>What Are</p>
                       <p className='fw-bolder fs-1 m-0'>You</p>
                       <p className='fw-bolder fs-1 m-0'>Looking </p>
                       <p className='fw-bolder fs-1 m-0'>For?</p>

                       <p className='text-secondary mt-3' style={{fontSize:'14px'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                       </div>
                </div>

                <div className="col-md-6">
                    <div className="row m-0">
                        <div className="col-md-6 mt-5">
                        <div className='bg-white p-4 shadow' style={{height:'310px',borderRadius:'10px'}}>
                        <div className=''>
                        <p className='icons'><Work_svg1/></p>

                            <p className='fw-medium m-0 mt-3' style={{fontSize:'20px'}}>I'm a Tutor</p>
                        </div>
                        <p style={{color:'#9497A1',fontSize:'14px'}} className='mt-3'>At vero eos et accusamus et iusto odio dignissimos duciu quili blandit praesentium voluptatum ipsa quae ab illo.</p>
                    </div>
                        </div>

                        <div className="col-md-6">
                        <div className='bg-white p-4 shadow' style={{height:'310px',borderRadius:'10px'}}>
                        <div className=''>
                        <p className="icons"><Work_svg/></p>

                            <p className='fw-medium m-0' style={{fontSize:'20px'}}>I'm a Student</p>
                        </div>
                        <p style={{color:'#9497A1',fontSize:'14px'}} className='mt-3'>At vero eos et accusamus et iusto odio dignissimos duciu quili blandit praesentium voluptatum ipsa quae ab illo.</p>
                    </div>
                        </div>

                        <div className="col-md-6 mt-5">
                        <div className='bg-white p-4 shadow' style={{height:'310px',borderRadius:'10px'}}>
                        <div className=''>
                        <p className="icons"><Work_svg2/></p>
                            <p className='fw-medium m-0 mt-3' style={{fontSize:'20px'}}>I'm a School or College</p>
                        </div>
                        <p style={{color:'#9497A1',fontSize:'14px'}} className='mt-3'>At vero eos et accusamus et iusto odio dignissimos duciu quili blandit praesentium voluptatum ipsa quae ab illo.</p>
                    </div>
                        </div>

                        <div className="col-md-6">
                        <div className='bg-white p-4 shadow' style={{height:'310px',borderRadius:'10px'}}>
                        <div className=''>
                        <p className="icons"><Work_svg5/></p>

                            <p className='fw-medium m-0 mt-3' style={{fontSize:'20px'}}>I'm a Private Institute</p>
                        </div>
                        <p style={{color:'#9497A1',fontSize:'14px'}} className='mt-3'>At vero eos et accusamus et iusto odio dignissimos duciu quili blandit praesentium voluptatum ipsa quae ab illo.</p>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<Blogs/>
<Layout8/>
<FAQ/>
<Main_footer/>
<Backtotop/>
<Scrolltotop/>
    </div>
  )
}

export default How_it_works
