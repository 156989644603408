import React, { useEffect, useState } from 'react'
import Main_footer from '../Footer'
import Backtotop from '../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import { useNavigate } from 'react-router-dom'
import Navbar from '../Navbar'
import axios from 'axios'
import { hostname } from '../../App'
import BlogCards from '../Blog/BlogCards'

const All_blogs = () => {
  const navigate = useNavigate()
  let [allBlog, setAllBlog] = useState()
  let getAllBLog = () => {
    axios.get(`${hostname}/BlogViewSet/`).then((response) => {
      setAllBlog(response.data.reverse())
      console.log(response.data, 'blog');
    }).catch((error) => {
      console.log(error, 'blog');
    })
  }
  useEffect(() => {
    getAllBLog()
  }, [])
  return (
    <div>
      <Navbar />
      <div className="row m-0 container mx-auto pb-5 pt-4 mt-2">
        <p style={{ color: '#666666' }} className='fw-medium text-center'>OUR BLOGS</p>
        <p className='fs-1 fw-bolder text-center text-dark'>Find our all blogs from here</p>
        <p className='text-dark text-center mb-5 pb-5' style={{ fontSize: '16px' }}>
          Our blogs are written from very research and well known writers writers so that
          we can provide you the best blogs and articles articles for you to read them all along</p>

        {/* Blogs */}
        {
          allBlog && allBlog.map((obj) => (
            <BlogCards data={obj} />
          ))
        }

      </div>
      <Main_footer />
      <Backtotop />
      <Scrolltotop />
    </div>
  )
}

export default All_blogs
