import React, { useState } from 'react'
import Navbar from './Navbar'
import { useNavigate } from 'react-router-dom'
import Main_footer from './Footer'
import Backtotop from './Backtotop'
import Scrolltotop from '../Scrolltotop'
import Main_svg1 from '../svg/Main_svg1'
import Main_svg2 from '../svg/Main_svg2'
import Main_svg3 from '../svg/Main_svg3'
import Main_svg4 from '../svg/Main_svg4'
import Main_svg5 from '../svg/Main_svg5'
import Main_svg6 from '../svg/Main_svg6'

const Main_page = () => {
    const navigate=useNavigate()

    const[state,setstate]=useState('language')

  return (
    <div className='animate__animated animate__fadeIn'>
      <div className='main-div'>
        <Navbar value={"home"} color={'rgb(121,6,210)'}/>
       <div className='row m-0 container container1 mx-auto main-div1 align-items-center'>
        <div className="col-lg-6">
        <p className='m-0'><span className='main-text' style={{color:'#4EB1F8'}}>MERIDA</span><span className='main-text ms-3 text-white'>STARTED</span></p>
        <p className='m-0' style={{lineHeight:'40px'}}><span className='main-text' style={{color:'#4EB1F8'}}>OFFLINE</span><span className='main-text ms-3 text-white'>CLASS</span></p>
        <p className='fs-5 text-white mt-3'>Grow your business to the next level to improve your business performance to stay competitive</p>

        <div className='mt-4 pt-2'>
            <button className='btn main-btn text-white rounded-pill fw-medium px-4 shadow-sm' onClick={()=>{
                navigate('/add_tutor')
            }}>BECOME A TUTOR</button>
        </div>
        </div>
        <div className="col-lg-6 pt-4">
            <div className='main-form p-4 py-5'>
                <p className='fs-2 fw-bold text-center' style={{color:'#FFCB24'}}>Join to the best Classes</p>
                <p className='text-white text-center'>Suspendisse morbi mauris gravida tellus integer ucibus tellus inut condimentum blandit mus auctor nulla.</p>

                <div className='mt-4'>
                    <input type="text" className='form-control py-3 bg-white shadow-none border-0 mb-4' placeholder='Name' name="" id="" />
                    <input type="email" className='form-control py-3 bg-white shadow-none border-0 mb-4' placeholder='Email' name="" id="" />
                    <input type="tel" maxLength={10} className='form-control py-3 bg-white shadow-none border-0 mb-4' placeholder='Contact Number' name="" id="" />
                    <select type="text" className='form-select py-3 bg-white shadow-none border-0 mb-4' name="" id="">
                        <option value="">Select the Purpose</option>
                        <option value="tutor">Tutor</option>
                        <option value="student">Student</option>
                    </select>
                    <textarea type="text" className='form-control py-3 bg-white shadow-none border-0 mb-4' placeholder='Type your message here...' name="" id="" />

                    <button className='btn text-dark fw-bold w-100 py-3 d-flex align-items-center justify-content-center' style={{backgroundColor:'#FCC106'}}>Get Started 
                        <span className='ms-1'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></span>
                    </button>

                </div>
            </div>
        </div>
       </div>
      </div>

      {/* Layout 2 */}
      <div className="main-div2 bg-white py-4">
      <div>
    <p className='fs-4 fw-medium text-center m-0'>Find Your</p>
    <p className='fs-1 fw-bold text-center m-0'>Ideal Tutor in Just a Few Clicks!</p>
    <p className='text-center mb-5'>Personalized tutoring sessions tailored to your needs. Book now and start excelling!</p>
</div>
      <div className="row m-0 pt-4 container mx-auto align-items-center">
<div className="col-lg-6 px-md-5">
    <img src={require('../images/page1/Frame.png')} style={{width:'80%'}} alt="" />
</div>
<div className="col-lg-6 px-md-5 mt-4 mt-lg-0">
    <p className='m-0 fs-5 fw-medium'>Why Choose</p>
    <p className='fs-1 fw-bold mb-4'>Tutoring Hub?</p>

    <div className='d-flex align-items-start'>
        <img src={require('../images/page1/Group 106.png')} className='me-3' alt="" />
        <div>
            <p className='fs-5 fw-medium m-0'>Flexibility</p>
            <span style={{fontSize:'14px'}}>Give a detailed and factual review of your institution & help the future prospects in deciding the right college. Remember You’re a part of critical decision-making process. A reviewer, who has attended multiple institutions, can write a review for all those institutions.</span>
        </div>
    </div>

    <div className='d-flex align-items-start mt-5'>
        <img src={require('../images/page1/Group 105.png')} className='me-3' alt="" />
        <div>
            <p className='fs-5 fw-medium m-0'>Qualified Teachers</p>
            <span style={{fontSize:'14px'}}>Give a detailed and factual review of your institution & help the future prospects in deciding the right college. Remember You’re a part of critical decision-making process. A reviewer, who has attended multiple institutions, can write a review for all those institutions.</span>
        </div>
    </div>
</div>
</div>
      </div>

{/* About Us */}
      <div className="m-0 row mt-5 align-items-center container mx-auto py-5">
      <div className="col-md-6 px-md-5 mt-2">
            <p className='fs-1 fw-bold'>About US</p>

           <p className=''>Campus Ambassador Program has been initiated to recognize smart and dedicated college students and  prov ide them with adequate opportunities to learn the industry-relevant skills, while earning Cash rewards and  certifications from our Industry-experts.</p>
        </div>

        <div className="col-md-6 px-md-5 mt-2 text-end">
            <img src={require('../images/page1/illustrator.png')} style={{width:'80%'}} alt="" />
        </div>

        <div className='col-lg-9 mx-auto'>
        <p className='fs-1 fw-bold m-0'>Benefit to</p>
        <p className='fs-1 fw-bold m-0' style={{lineHeight:'24px'}}>Join as a Student</p>

        <div className='row m-0 mt-5'>
            <div className="col-md-5 mb-3 mb-md-0">
            <div className='p-2 px-3 about-us shadow'>
                <img src={require('../images/page1/Group 91.png')} alt="" />
                <p className='fs-6 ms-3 fw-medium m-0'>Certificates of Internship/LOR</p>
            </div>
            </div>
            <div className="col-md-5">
            <div className='p-2 px-3 about-us shadow' style={{height:'64px'}}>
                <img src={require('../images/page1/Group 100.png')} alt="" />
                <p className='fs-6 ms-3 fw-medium m-0'>Free Subscription Vouchers</p>
            </div>
            </div>
        </div>

        <div className='row m-0 mt-4 pt-2'>
            <div className="col-md-5 mb-3 mb-md-0">
            <div className='p-2 px-3 about-us shadow'>
                <img src={require('../images/page1/Group.png')} alt="" />
                <p className='fs-6 ms-3 fw-medium m-0'>Featuring on College.com</p>
            </div>
            </div>
            <div className="col-md-5">
            <div className='p-2 px-3 about-us shadow' style={{height:'64px'}}>
                <img src={require('../images/page1/Group 92.png')} alt="" />
                <p className='fs-6 ms-3 fw-medium m-0'>Instant PayTM cash payments</p>
            </div>
            </div>
        </div>

        <div className='row m-0 mt-4 pt-2'>
            <div className="col-md-4 mb-3 mb-md-0">
            <div className='p-2 px-3 about-us shadow'>
                <img src={require('../images/page1/Group 93.png')} alt="" />
                <p className='fs-6 ms-3 fw-medium m-0'>Social Media Promotions</p>
            </div>
            </div>
            <div className="col-md-7">
            <div className='p-2 px-3 about-us shadow' style={{height:'64px'}}>
                <img src={require('../images/page1/Group 101.png')} alt="" />
                <p className='fs-6 ms-3 fw-medium m-0'>Internship opportunities at College.com HQ</p>
            </div>
            </div>
        </div>

        <div className='row m-0 mt-4 pt-2'>
            <div className="col-md-6 mb-3 mb-md-0">
            <div className='p-2 px-3 about-us shadow'>
                <img src={require('../images/page1/Group 102.png')} alt="" />
                <p className='fs-6 ms-3 fw-medium m-0'>Free Webinars and Counselling sessions</p>
            </div>
            </div>
            <div className="col-md-6">
            <div className='p-2 px-3 about-us shadow' style={{height:'64px'}}>
                <img src={require('../images/page1/Group 69.png')} alt="" />
                <p className='fs-6 ms-3 fw-medium m-0'>Exclusive Collegedunia Merchandise</p>
            </div>
            </div>
        </div>

      </div>
      </div>    

{/* What will you be */}
<div className="py-5 container mx-auto">
    <p className='fs-1 fw-bold mb-4'>What will you be up to?</p>
<div className="row m-0 bg-white shadow p-4" style={{borderRadius:'10px'}}>
<div className="col-md-6 col-lg-4 ps-md-0 mb-4 mb-md-0">
    <div className='d-flex align-items-center flex-column about-us2'>
        <img src={require('../images/page1/Group 99.png')} alt="" />
        <p className='text-center fw-medium fs-5 mt-2 m-0'>Review Collation</p>
        <p className='text-center mt-2'>Collate College reviews, on Collegedunia.com using your referral code and get paid for each approved Review.</p>
    </div>
</div>

<div className="col-md-6 col-lg-4 mb-4 mb-md-0">
    <div className='d-flex align-items-center flex-column about-us2'>
        <img src={require('../images/page1/Group 96.png')} alt="" />
        <p className='text-center fw-medium fs-5 mt-2 m-0'>Refer your friends</p>
        <p className='text-center mt-2'>Refer your friends to join the program, and Gain paytm cash for every approved review that they get.</p>
    </div>
</div>

<div className="col-md-6 col-lg-4 pe-md-0 mt-4 mt-lg-0">
    <div className='d-flex align-items-center flex-column about-us2'>
        <img src={require('../images/page1/Group 98.png')} alt="" />
        <p className='text-center fw-medium fs-5 m-0 mt-2'>Representation</p>
        <p className='text-center mt-2'>Represent us your campus and provide insights into your campus through Articles and Campus Tour Videos</p>
    </div>
</div>

<div className="col-md-6 ps-md-0 mt-4">
    <div className='d-flex align-items-center flex-column about-us2'>
        <img src={require('../images/page1/Group 97.png')} alt="" />
        <p className='text-center fw-medium fs-5 mt-2 m-0'>Collaborations</p>
        <p className='text-center mt-2'>Find, build and promote collaborations/ partnerships for Collegedunia with like- minded communities within your institution, promoting mutual learning and growth.</p>
    </div>
</div>

<div className="col-md-6 pe-md-0 mt-4">
    <div className='d-flex align-items-center flex-column about-us2'>
        <img src={require('../images/page1/Group 95.png')} alt="" />
        <p className='text-center fw-medium fs-5 m-0 mt-2'>Guest Interviews</p>
        <p className='text-center mt-2'>Conduct insightful Student and Faculty interviews to provide information about your institution, and learn about their vision for the growth and welfare of the student.</p>
    </div>
</div>
</div>
</div>

{/* Rewards */}
<div className="row m-0 container mx-auto py-5 align-items-end justify-content-between">
    <p className='fw-bold fs-1 mb-4'>Milestones and Rewards</p>
<div className="col-lg-2 mb-4 mb-md-0">
    <p className='m-0 d-flex align-items-center fw-bold fs-5'><img src={require('../images/page1/star.png')} className='me-2' alt="" />5</p>
    <span>Approved Reviews</span>

    <div className='rewards'>
        <p>Certificate of Participation (Beginner)</p>
    </div>
</div>
<div className="col-lg-2 mb-4 mb-md-0">
    <p className='m-0 d-flex align-items-center fw-bold fs-5'><img src={require('../images/page1/star.png')} className='me-2' alt="" />20</p>
    <span>Approved Reviews</span>

    <div className='rewards'>
        <p>Certificate of Participation</p>
        <p>Exclusive Collegedunia merchandise</p>
    </div>
</div>
<div className="col-lg-2 mb-4 mb-md-0">
    <p className='m-0 d-flex align-items-center fw-bold fs-5'><img src={require('../images/page1/star.png')} className='me-2' alt="" />35</p>
    <span>Approved Reviews</span>

    <div className='rewards'>
        <p>Internship Certificate (Master)</p>
        <p>Additional Paytm Cash Reward</p>
        <p>Social Media Promotions</p>
    </div>
</div>
<div className="col-lg-2 mb-4 mb-md-0">
    <p className='m-0 d-flex align-items-center fw-bold fs-5'><img src={require('../images/page1/star.png')} className='me-2' alt="" />50</p>
    <span>Approved Reviews</span>

    <div className='rewards'>
        <p>Certificate of Recognition (Jedi) and LOR</p>
        <p>Paytm Cash Reward (upto INR 500)</p>
        <p>Social Media</p>
    </div>
</div>
<div className="col-lg-2">
    <p className='m-0 d-flex align-items-center fw-bold fs-5'><img src={require('../images/page1/star.png')} className='me-2' alt="" /> 50</p>
    <span>Approved Reviews</span>

    <div className='rewards'>
        <p>Paytm Cash Reward Per 25 Approved Reviews</p>
        <p>Featured Interview on CD Website and Social Media</p>
        <p>Feature on CD Website and Social Media</p>
    </div>
</div>

<div>
<p className='fw-bold fs-1 mb-3 mt-5'>How to Join</p>
<p className='d-flex align-items-center'><img src={require('../images/page1/Group 107.png')} className='me-2' alt="" /> Submit your review for your college using the Review Form. You will get your Referral code after submitting a review of your college.</p>
<p className='d-flex align-items-center'><img src={require('../images/page1/Group 107.png')} className='me-2' alt="" /> Refer your colleagues to join as a campus ambassador using this Referral form. </p>
</div>
</div>

{/* Subjects,Languages,Boards */}
<div className='review py-5'>
<div className="container">
    <p className='fs-1 fw-bold text-white text-center'>Categories</p>

    <div className='row m-0 mt-4'>
        <div className="col-md-6 mx-auto rounded-pill" style={{backgroundColor:'rgba(0,0,0,0.1)'}}>
            <div className="row m-0 w-100 py-2">
                <div className="col-4 ps-md-0">
                    <p onClick={()=>{
                        setstate('language')
                    }} className={`my-auto py-2 rounded-pill text-center ${state==='language' ? 'selected-btn':'select-btn'}`} style={{cursor:'pointer'}}>Languages</p>
                </div>
                <div className="col-4">
                    <p onClick={()=>{
                        setstate('subject')
                    }} className={`my-auto py-2 rounded-pill text-center ${state==='subject' ? 'selected-btn':'select-btn'}`} style={{cursor:'pointer'}}>Subjects</p>
                </div>
                <div className="col-4 pe-md-0">
                    <p onClick={()=>{
                        setstate('board')
                    }} className={`my-auto py-2 rounded-pill text-center ${state==='board' ? 'selected-btn':'select-btn'}`} style={{cursor:'pointer'}}>Boards</p>
                </div>
            </div>
        </div>
    </div>

    <p className='text-white text-center mt-4'>Nullam vestibulum, justo vitae varius interdum, nibh turpis eleifend tellus, sit amet porttitor orci erat vitae odio. Duis sapien metus, egestas rhoncus metus id</p>

    <div className="row m-0 mt-5">
        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow' style={{cursor:'pointer'}} onClick={()=>{
                navigate('/language')
            }}>
                <div className="text-center">
                    <Main_svg1/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>English</p>
                <p className='mt-4 text-center'>Aenean varius ultricies dapibus. Vivamus et tortor et libero cursus facilisis et a tortor. Etiam eleifend eleifend</p>

                <img src={require('../../src/images/page1/divider.png')} className='d-block mx-auto mt-5' alt="" />
            </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center">
                    <Main_svg2/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>Tamil</p>
                <p className='mt-4 text-center'>Aenean varius ultricies dapibus. Vivamus et tortor et libero cursus facilisis et a tortor. Etiam eleifend eleifend</p>

                <img src={require('../../src/images/page1/divider.png')} className='d-block mx-auto mt-5' alt="" />
            </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center">
                    <Main_svg3/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>German</p>
                <p className='mt-4 text-center'>Aenean varius ultricies dapibus. Vivamus et tortor et libero cursus facilisis et a tortor. Etiam eleifend eleifend</p>

                <img src={require('../../src/images/page1/divider.png')} className='d-block mx-auto mt-5' alt="" />
            </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center">
                    <Main_svg4/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>Telugu</p>
                <p className='mt-4 text-center'>Aenean varius ultricies dapibus. Vivamus et tortor et libero cursus facilisis et a tortor. Etiam eleifend eleifend</p>

                <img src={require('../../src/images/page1/divider.png')} className='d-block mx-auto mt-5' alt="" />
            </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center">
                    <Main_svg5/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>Kannada</p>
                <p className='mt-4 text-center'>Aenean varius ultricies dapibus. Vivamus et tortor et libero cursus facilisis et a tortor. Etiam eleifend eleifend</p>

                <img src={require('../../src/images/page1/divider.png')} className='d-block mx-auto mt-5' alt="" />
            </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center">
                    <Main_svg6/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>Malayalam</p>
                <p className='mt-4 text-center'>Aenean varius ultricies dapibus. Vivamus et tortor et libero cursus facilisis et a tortor. Etiam eleifend eleifend</p>

                <img src={require('../../src/images/page1/divider.png')} className='d-block mx-auto mt-5' alt="" />
            </div>
        </div>
        
    </div>
</div>



{/* Reviews */}
<div className="py-5 mt-4">
    <p className='fs-1 fw-bold text-white text-center'>Student’s Testimonials</p>
    <div className="row m-0 review-subdiv py-5 container mx-auto justify-content-between" style={{borderRadius:'15px'}}>
        <h5 className='text-white mb-4 ps-5'>Hear from our beloved Students</h5>
        <div className="col-lg-6 px-2 px-md-5 mb-4">
            <div className="row m-0 reviews-div">
                <div className="col-4">
                    <img src={require('../images/page1/Group 1171276044.png')} alt="" className='d-block mx-auto'/>
                    <p className='text-center text-white fw-medium m-0'>Madhavan</p>
                    <p className='text-center text-white' style={{fontSize:'13px'}}>Bangalore,Karnataka.</p>

                    <div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <img src={require('../images/page1/Group 85.png')} alt="" />
                            <img src={require('../images/page1/Group 83.png')} alt="" />
                        </div>
                        <div className='d-flex align-items-center justify-content-between mt-3'>
                            <img src={require('../images/page1/Group 88.png')} alt="" />
                            <img src={require('../images/page1/Group 84.png')} alt="" />
                        </div>
                    </div>
                </div>

                <div className="col-8 ps-5 pe-0 d-flex flex-column justify-content-between">
                   <div>
                   <p className='fs-5 review-subdiv2 justify-content-center rounded p-1 fw-bold d-flex align-items-center text-white'><img src={require('../images/page1/star.png')} className='me-2' alt="" /> 3 <span className='ms-2 fw-normal' style={{fontSize:'14px'}}>Years of Experience</span></p>
                   <p className='text-white' style={{fontSize:'14px'}}>“The annual is named as 'SANGRILLA' which is the best thing I want to talk about and which is the best time... The annual is named as 'SANGRILLA' which is the best thing I want to talk about and which is the best time..</p>
                   </div>
                    <p className='text-white m-0' style={{fontSize:'14px'}}>Kingston School of Management and Science</p>

                </div>
            </div>
        </div>
        <div className="col-lg-6 px-2 px-md-5 mb-4">
            <div className="row m-0 reviews-div">
                <div className="col-4">
                    <img src={require('../images/page1/Group 1171276044.png')} alt="" className='d-block mx-auto'/>
                    <p className='text-center text-white fw-medium m-0'>Madhavan</p>
                    <p className='text-center text-white' style={{fontSize:'13px'}}>Bangalore,Karnataka.</p>

                    <div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <img src={require('../images/page1/Group 85.png')} alt="" />
                            <img src={require('../images/page1/Group 83.png')} alt="" />
                        </div>
                        <div className='d-flex align-items-center justify-content-between mt-3'>
                            <img src={require('../images/page1/Group 88.png')} alt="" />
                            <img src={require('../images/page1/Group 84.png')} alt="" />
                        </div>
                    </div>
                </div>

                <div className="col-8 ps-5 pe-0 d-flex flex-column justify-content-between">
                   <div>
                   <p className='fs-5 review-subdiv2 justify-content-center rounded p-1 fw-bold d-flex align-items-center text-white'><img src={require('../images/page1/star.png')} className='me-2' alt="" /> 5 <span className='ms-2 fw-normal' style={{fontSize:'14px'}}>Years of Experience</span></p>
                   <p className='text-white' style={{fontSize:'14px'}}>“The annual is named as 'SANGRILLA' which is the best thing I want to talk about and which is the best time... The annual is named as 'SANGRILLA' which is the best thing I want to talk about and which is the best time..</p>
                   </div>
                    <p className='text-white m-0' style={{fontSize:'14px'}}>Kingston School of Management and Science</p>

                </div>
            </div>
        </div>
        <div className="col-lg-6 px-2 px-md-5 mb-4 mb-lg        -0">
            <div className="row m-0 reviews-div">
                <div className="col-4">
                    <img src={require('../images/page1/Group 1171276044.png')} alt="" className='d-block mx-auto'/>
                    <p className='text-center text-white fw-medium m-0'>Madhavan</p>
                    <p className='text-center text-white' style={{fontSize:'13px'}}>Bangalore,Karnataka.</p>

                    <div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <img src={require('../images/page1/Group 85.png')} alt="" />
                            <img src={require('../images/page1/Group 83.png')} alt="" />
                        </div>
                        <div className='d-flex align-items-center justify-content-between mt-3'>
                            <img src={require('../images/page1/Group 88.png')} alt="" />
                            <img src={require('../images/page1/Group 84.png')} alt="" />
                        </div>
                    </div>
                </div>

                <div className="col-8 ps-5 pe-0 d-flex flex-column justify-content-between">
                   <div>
                   <p className='fs-5 review-subdiv2 justify-content-center rounded p-1 fw-bold d-flex align-items-center text-white'><img src={require('../images/page1/star.png')} className='me-2' alt="" /> 2 <span className='ms-2 fw-normal' style={{fontSize:'14px'}}>Years of Experience</span></p>
                   <p className='text-white' style={{fontSize:'14px'}}>“The annual is named as 'SANGRILLA' which is the best thing I want to talk about and which is the best time... The annual is named as 'SANGRILLA' which is the best thing I want to talk about and which is the best time..</p>
                   </div>
                    <p className='text-white m-0' style={{fontSize:'14px'}}>Kingston School of Management and Science</p>

                </div>
            </div>
        </div>
        <div className="col-lg-6 px-2 px-md-5">
            <div className="row m-0 reviews-div">
                <div className="col-4">
                    <img src={require('../images/page1/Group 1171276044.png')} alt="" className='d-block mx-auto'/>
                    <p className='text-center text-white fw-medium m-0'>Madhavan</p>
                    <p className='text-center text-white' style={{fontSize:'13px'}}>Bangalore,Karnataka.</p>

                    <div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <img src={require('../images/page1/Group 85.png')} alt="" />
                            <img src={require('../images/page1/Group 83.png')} alt="" />
                        </div>
                        <div className='d-flex align-items-center justify-content-between mt-3'>
                            <img src={require('../images/page1/Group 88.png')} alt="" />
                            <img src={require('../images/page1/Group 84.png')} alt="" />
                        </div>
                    </div>
                </div>

                <div className="col-8 ps-5 pe-0 d-flex flex-column justify-content-between">
                   <div>
                   <p className='fs-5 review-subdiv2 justify-content-center rounded p-1 fw-bold d-flex align-items-center text-white'><img src={require('../images/page1/star.png')} className='me-2' alt="" /> 4 <span className='ms-2 fw-normal' style={{fontSize:'14px'}}>Years of Experience</span></p>
                   <p className='text-white' style={{fontSize:'14px'}}>“The annual is named as 'SANGRILLA' which is the best thing I want to talk about and which is the best time... The annual is named as 'SANGRILLA' which is the best thing I want to talk about and which is the best time..</p>
                   </div>
                    <p className='text-white m-0' style={{fontSize:'14px'}}>Kingston School of Management and Science</p>

                </div>
            </div>
        </div>
    </div>
</div>
</div>

{/* Rules */}
<div className='row m-0 container mx-auto py-5 align-items-center'>
<p className='fs-1 fw-bold text-center mb-5'>Rules and Guidelines</p>

<div className="col-md-6">          
    <img src={require('../images/page1/Group 114.png')} style={{width:'90%'}} alt="" />
</div>
<div className="col-md-6 px-2 px-md-5">
    <p className='fw-bold mb-4 fs-5'>Key Point</p>
    <p className='d-flex align-items-center'><img src={require('../images/page1/Group 107.png')} className='me-2' alt="" /> Add maximum details to your answer.</p>
<p className='d-flex align-items-center'><img src={require('../images/page1/Group 107.png')} className='me-2' alt="" /> There should be no plagiarism and negative content about the college. </p>
<p className='d-flex align-items-center'><img src={require('../images/page1/Group 107.png')} className='me-2' alt="" /> Review should be written only for the courses offered by the college.</p>
<p className='d-flex align-items-center'><img src={require('../images/page1/Group 107.png')} className='me-2' alt="" /> Mention facts and figures as per your best knowledge. </p>
<p className='d-flex align-items-center'><img src={require('../images/page1/Group 107.png')} className='me-2' alt="" /> A good validated review will also lead to better reward. </p>

<button className='px-5 fw-medium btn py-2 mt-4' style={{color:'#8236C8',border:'1px solid #8236C8'}}>Read Terms & Guidelines</button>
</div>
</div>

<div className='row m-0 container mx-auto py-5 align-items-center justify-content-center'>

<div className="col-md-5 px-md-5">
    <p className='fs-5 fw-medium m-0'>Help millions of students in making informed career decisions.</p>
</div>
<div className="col-md-5 px-2 px-md-5">
<button className='px-5 fw-medium btn py-2 text-white' style={{backgroundColor:'#8236C8',border:'1px solid #8236C8'}}>Join and Refer to your friend</button>
</div>
</div>

      <div>

      </div>
      <Main_footer/>
      <Backtotop/>
      <Scrolltotop/>
    </div>
  )
}

export default Main_page
