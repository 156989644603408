import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { hostname } from '../../App'
import BlogCards from './BlogCards'

const PopularBLog = () => {
    let [allBlog, setAllBlog] = useState()
    let getAllBLog = () => {
        axios.get(`${hostname}/BlogViewSet/`).then((response) => {
            setAllBlog(response.data.reverse())
            console.log(response.data, 'blog');
        }).catch((error) => {
            console.log(error, 'blog');
        })
    }
    let navigate = useNavigate()
    useEffect(() => {
        getAllBLog()
    }, [])
    return (
        <div>
            <div className='mt-5 pt-5'>
                <div className="d-flex align-items-center justify-content-between">
                    <p className='fs-1 fw-bolder m-0'>Popular Post</p>
                    <button className='btn px-4 py-2 text-white' style={{ fontSize: '14px', backgroundColor: '#7C4EE4' }} onClick={() => {
                        navigate('/blogs')
                    }}>View all</button>
                </div>

                <div className="row m-0 container mx-auto py-5">

                    {
                        allBlog && allBlog.reverse().slice(0, 3).map((obj) => (
                            <BlogCards data={obj} />
                        ))
                    }

                </div>
            </div>
        </div>
    )
}

export default PopularBLog