import React from 'react'
import { Modal } from 'react-bootstrap'

const Tutor_details = ({show,setshow,value}) => {
  return (
    <div>
      <Modal show={show} size='lg' onHide={()=>{
        setshow(false)
      }}>
        <Modal.Body className='p-3 rounded' style={{backgroundColor:'#F3F3FD'}}>
            <div className='d-flex pb-3 mb-3 border-bottom align-items-center justify-content-between'>
                <h5 className='m-0 text-primary'>{value && value.name}'s Profile</h5>
                <svg style={{cursor:'pointer'}} onClick={()=>{
                    setshow(false)
                }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
            </div>
            <div className='row m-0'>
                <div className="col-md-6 ps-md-0 mb-3">
                    <p><span className='labels'>Name : </span> <span>{value && value.name}</span></p>
                </div>
                <div className="col-md-6 pe-md-0 mb-3">
                    <p><span className='labels'>Contact Number : </span> <span>{value && value.contact_number}</span></p>
                </div>
                <div className="col-md-6 ps-md-0 mb-3">
                    <p><span className='labels'>Whatsapp Number : </span> <span>{value && value.whatsapp_number}</span></p>
                </div>
                <div className="col-md-6 pe-md-0 mb-3">
                    <p><span className='labels'>Gender : </span> <span>{value && value.gender}</span></p>
                </div>
                <div className="col-12 ps-md-0 mb-3">
                    <p><span className='labels'>Address : </span> <span>{value && value.address}</span></p>
                </div>
                <div className="col-12 ps-md-0 mb-3">
                    <p><span className='labels'>Experience : </span> <span>{value && value.experience}</span></p>
                </div>

                <div className="col-md-6 ps-md-0 mb-3">
                    <p><span className='labels'>Mode : </span> <span>{value && value.mode}</span></p>
                </div>

                {value && value.location && (<div className="col-md-6 pe-md-0 mb-3">
                    <p><span className='labels'>Location : </span> <span>{value && value.location}</span></p>
                </div>)}

                <div className="col-md-6 ps-md-0 mb-3">
                    <p><span className='labels'>Charges : </span> <span>{value && value.charges}</span></p>
                </div>
                <div className="col-md-6 pe-md-0 mb-3">
                    <p><span className='labels'>Fees : </span> <span>{value && value.fees}</span></p>
                </div>
            </div>

            <div className='m-0 border-top pt-4'>
                <div className="row mb-3">
                    <div className="col-md-4">
                    <p><span className='labels'>Subjects : </span> </p>
                    </div>
                   <div className="col-md-8 pe-md-0" style={{maxHeight:'160px',overflowY:'scroll'}}>
                   {value && value.subjects && value.subjects.map((x,index)=>{
                        return(
                            <>
                            <p>{index+1}] {x.name}</p>
                            </>
                        )
                    })}
                   </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4">
                    <p><span className='labels'>Boards : </span> </p>
                    </div>
                   <div className="col-md-8 pe-md-0" style={{maxHeight:'160px',overflowY:'scroll'}}>
                   {value && value.boards && value.boards.map((x,index)=>{
                        return(
                            <>
                            <p>{index+1}] {x.name}</p>
                            </>
                        )
                    })}
                   </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4">
                    <p><span className='labels'>Extra Activities : </span> </p>
                    </div>
                   <div className="col-md-8 pe-md-0" style={{maxHeight:'160px',overflowY:'scroll'}}>
                   {value && value.extractivity && value.extractivity.map((x,index)=>{
                        return(
                            <>
                            <p>{index+1}] {x.name}</p>
                            </>
                        )
                    })}
                   </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4">
                    <p><span className='labels'>Standards : </span> </p>
                    </div>
                   <div className="col-md-8 pe-md-0" style={{maxHeight:'160px',overflowY:'scroll'}}>
                   {value && value.standards && value.standards.map((x,index)=>{
                        return(
                            <>
                            <p>{index+1}] {x.name}</p>
                            </>
                        )
                    })}
                   </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4">
                    <p><span className='labels'>Languages Known : </span> </p>
                    </div>
                   <div className="col-md-8 pe-md-0" style={{maxHeight:'160px',overflowY:'scroll'}}>
                   {value && value.languages && value.languages.map((x,index)=>{
                        return(
                            <>
                            <p>{index+1}] {x.name}</p>
                            </>
                        )
                    })}
                   </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4">
                    <p><span className='labels'>Preferred Timings : </span> </p>
                    </div>
                   <div className="col-md-8 pe-md-0" style={{maxHeight:'160px',overflowY:'scroll'}}>
                   {value && value.timings && value.timings.map((x,index)=>{
                        return(
                            <>
                            <p>{index+1}] {x.start_time} - {x.end_time}</p>
                            </>
                        )
                    })}
                   </div>
                </div>
            </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Tutor_details
