import React from 'react'
import Blogs from '../Subjects/Blogs'
import Main_footer from '../Footer'
import Backtotop from '../Backtotop'
import Layout8 from '../Subjects/Layout8'
import Navbar from '../Navbar'
import Scrolltotop from '../../Scrolltotop'
import FAQ from '../Subjects/FAQ'

const Online_page = () => {

  return (
    <div className='animate__animated animate__fadeIn'>

      {/* <div className='py-4' style={{backgroundColor:'#F1F5F9'}}>

      </div> */}

     
      {/* Lyout2 */}
      <div className="layout2 pb-5">
      <Navbar value={"online"}/>
        <p className="online-text1 fw-bold text-center m-0">
        Online student
        </p>
        <p className='fw-medium text-center'>Sit elit feugiat turpis sed integer integer accumsan turpis. Sed suspendisse nec lorem mauris.
        Pharetra, eu imperdiet ipsum ultrices amet, dui sit suspendisse.</p>

        <div className='text-center'>
            <img src={require('../../images/online/Desktop.png')} style={{width:'84%',height:'100%'}} alt="" />
        </div>
        <div className="text-center">
        <img src={require('../../images/online/Frame 2300.png')} style={{width:'80%'}} alt="" />
        </div>
      </div>

      <p className='fs-1 fw-bolder text-center pt-5 mt-4'>Teach students worldwide</p>
      <div className='online-content py-5'>
        <div className="container">
          <p className='text-center text-white mb-4'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Explicabo quas cum eveniet! Quas soluta nam, veniam fugiat id laboriosam eligendi quo in, culpa quos, maiores sequi quis! Minus inventore atque vero beatae voluptatibus placeat reprehenderit reiciendis architecto debitis ab veniam, accusantium possimus sed, quae asperiores laboriosam magni cupiditate neque sequi, ipsam praesentium repellat quia sit optio. Voluptates totam sequi quam at, quibusdam ratione, id amet quo consequatur ipsum natus recusandae beatae porro ipsam molestiae quisquam. Quibusdam animi at, labore tenetur aliquam facilis architecto atque nemo, adipisci asperiores quidem quia impedit molestias eaque quam excepturi, aut iure rerum esse voluptatem dolor?</p>
          <p className='text-center text-white mb-4'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Explicabo quas cum eveniet! Quas soluta nam, veniam fugiat id laboriosam eligendi quo in, culpa quos, maiores sequi quis! Minus inventore atque vero beatae voluptatibus placeat reprehenderit reiciendis architecto debitis ab veniam, accusantium possimus sed, quae asperiores laboriosam magni cupiditate neque sequi, ipsam praesentium repellat quia sit optio. Voluptates totam sequi quam at, quibusdam ratione, id amet quo consequatur ipsum natus recusandae beatae porro ipsam molestiae quisquam. Quibusdam animi at, labore tenetur aliquam facilis architecto atque nemo, adipisci asperiores quidem quia impedit molestias eaque quam excepturi, aut iure rerum esse voluptatem dolor?</p>
          <p className='text-center text-white mb-4'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Explicabo quas cum eveniet! Quas soluta nam, veniam fugiat id laboriosam eligendi quo in, culpa quos, maiores sequi quis! Minus inventore atque vero beatae voluptatibus placeat reprehenderit reiciendis architecto debitis ab veniam, accusantium possimus sed, quae asperiores laboriosam magni cupiditate neque sequi, ipsam praesentium repellat quia sit optio. Voluptates totam sequi quam at, quibusdam ratione, id amet quo consequatur ipsum natus recusandae beatae porro ipsam molestiae quisquam. Quibusdam animi at, labore tenetur aliquam facilis architecto atque nemo, adipisci asperiores quidem quia impedit molestias eaque quam excepturi, aut iure rerum esse voluptatem dolor?</p>
          <p className='text-center text-white'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Explicabo quas cum eveniet! Quas soluta nam, veniam fugiat id laboriosam eligendi quo in, culpa quos, maiores sequi quis! Minus inventore atque vero beatae voluptatibus placeat reprehenderit reiciendis architecto debitis ab veniam, accusantium possimus sed, quae asperiores laboriosam magni cupiditate neque sequi, ipsam praesentium repellat quia sit optio. Voluptates totam sequi quam at, quibusdam ratione, id amet quo consequatur ipsum natus recusandae beatae porro ipsam molestiae quisquam. Quibusdam animi at, labore tenetur aliquam facilis architecto atque nemo, adipisci asperiores quidem quia impedit molestias eaque quam excepturi, aut iure rerum esse voluptatem dolor?</p>
          
        
        </div>
      </div>


      {/* Layout3 */}
      <div className="row container mx-auto m-0 pb-5 mb-5" style={{paddingTop:'140px'}}>
        <div className="col-lg-6">
            <p className='online-text fw-bold' style={{lineHeight:'56px'}}>An all-in-one app that makes it easier</p>
            <div className='mt-4'>
                <p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
</svg> <span className='ms-2 fw-medium'>Est et in pharetra magna adipiscing ornare aliquam.</span></p>
<p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
</svg> <span className='ms-2 fw-medium'>Est et in pharetra magna adipiscing ornare aliquam.</span></p>
<p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
</svg> <span className='ms-2 fw-medium'>Est et in pharetra magna adipiscing ornare aliquam.</span></p>
            </div>
            <p className='d-flex align-items-center fw-bold mt-5' style={{color:'#2563EB',cursor:'pointer'}}>Find more about the app <span className='ms-3'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg></span></p>
        </div>
        <div className="col-lg-6 ps-md-5 video-div" style={{position:'relative'}}>
        <img src={require('../../images/online/Group 1171276074.png')} className='online-img1' style={{width:'90%',borderRadius:'10px',zIndex:1}} alt="" />

          <img src={require('../../images/online/Video.png')} className='d-block ms-auto online-img2' style={{width:'100%',borderRadius:'10px',zIndex:2}} alt="" />

          <div className='row m-0 w-100 online-divs mt-5 mt-md-0' style={{zIndex:3}}>
            <div className="col-md-4 ps-md-0 mb-4 mb-md-0">
             <div className='bg-white p-3 rounded shadow'>
             <span className='px-3 py-1 rounded' style={{color:'#6B21A8',backgroundColor:'#F3E8FF'}}>Featured</span>
              <p className='fw-bold m-0 my-2'>The map of mathematics</p>
              <p style={{color:'#475569',fontSize:'13px'}} className='m-0'>Egestas elit dui scelerisque ut eu purus aliquam vitae habitasse.</p>
              <button className='btn px-4 mt-3 fw-medium' style={{border:'2px solid #2563EB',color:'#2563EB',fontSize:'13px'}}>Take Lesson</button>
             </div>
            </div>
            <div className="col-md-4 ps-md-0 mb-4 mb-md-0">
             <div className='bg-white p-3 rounded shadow'>
             <span className='px-3 py-1 rounded' style={{color:'#1E40AF',backgroundColor:'#DBEAFE'}}>Popular</span>
              <p className='fw-bold m-0 my-2'>Design for how people think</p>
              <p style={{color:'#475569',fontSize:'13px'}} className='m-0'>Egestas elit dui scelerisque ut eu purus aliquam vitae habitasse.</p>
              <button className='btn px-4 mt-3 fw-medium' style={{border:'2px solid #2563EB',color:'#2563EB',fontSize:'13px'}}>Take Lesson</button>
             </div>
            </div>
            <div className="col-md-4 ps-md-0 mb-4 mb-md-0">
             <div className='bg-white p-3 rounded shadow'>
             <span className='px-3 py-1 rounded' style={{color:'#166534',backgroundColor:'#DCFCE7'}}>New</span>
              <p className='fw-bold m-0 my-2'>International & commercial law</p>
              <p style={{color:'#475569',fontSize:'13px'}} className='m-0'>Egestas elit dui scelerisque ut eu purus aliquam vitae habitasse.</p>
              <button className='btn px-4 mt-3 fw-medium' style={{border:'2px solid #2563EB',color:'#2563EB',fontSize:'13px'}}>Take Lesson</button>
             </div>
            </div>
          </div>
        </div>
      </div>

      {/* Layout4 */}
      <div className="row m-0 container mx-auto align-items-center pb-5 mb-4" style={{paddingTop:'170px'}}>
        <div className="col-lg-6">
            <img src={require('../../images/online/Col.png')} style={{width:'90%'}} alt="" />
        </div>

        <div className="col-lg-6">
            <p className='online-text fw-bold' style={{lineHeight:'54px'}}>Meet international students & teachers</p>
            <p className='fw-medium'>Caption
Meet international students & teachers
It’s easier than ever before
Morbi sit egestas dignissim pharetra, sed amet. Tempus justo senectus risus ac vel, velit, nunc. Eget commodo eget in aliquam facilisi facilisi nec magna hendrerit. Placerat ipsum sit tellus urna, faucibus aenean lorem faucibus integer.
Explore teachers and students</p>

<p className='d-flex align-items-center fw-bold mt-5' style={{color:'#2563EB',cursor:'pointer'}}>Explore teachers and students<span className='ms-3'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg></span></p>
        </div>
      </div>
      <Blogs/>
      <Layout8/>

      {/* FAQ */}
      <FAQ/>
      <Main_footer/>
      <Backtotop/>
      <Scrolltotop/>

    </div>
  )
}

export default Online_page
