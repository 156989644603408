import React from 'react'
import Subject_svg1 from './Subject_svgs/Subject-svg1'
import Sunject_svg2 from './Subject_svgs/Sunject_svg2'
import Subject_svg3 from './Subject_svgs/Subject_svg3'
import Subject_svg4 from './Subject_svgs/Subject_svg4'
import Subject_svg5 from './Subject_svgs/Subject_svg5'
import FAQ_svg1 from './Subject_svgs/FAQ_svg1'
import { Accordion } from 'react-bootstrap'
import Main_footer from '../Footer'
import Backtotop from '../Backtotop'
import Blogs from './Blogs'
import Layout8 from './Layout8'
import Navbar from '../Navbar'
import Scrolltotop from '../../Scrolltotop'
import FAQ from './FAQ'

const Subject_page = () => {
  return (
    <div className='animate__animated animate__fadeIn'>
      <div className='subject-div1 pb-5'>
        <Navbar value={"subject"} color={'#F4F2F8'}/>
        <div className="row container mx-auto container1">
            <div className="col-md-6 mt-md-4">
                <p className='main-text m-0'><span style={{color:'#8236C8'}} className='main-text'>ICSE</span> Top Teachers</p>
                <p className='main-text m-0' style={{lineHeight:'48px'}}>Are here </p>
                <p className='fs-5 mt-5 m-0'>I am here ready to help you in making creative digital products</p>
                <button className='mt-3 btn text-white rounded-pill py-2 px-4' style={{backgroundColor:'#8236C8',fontSize:'13px'}}>Let's Discuss</button>
            </div>
            <div className="col-md-6 mt-md-4 text-center" style={{position:'relative'}}>
              <img src={require('../../images/student/image.png')} style={{width:'90%'}} alt="" />

              <div className='subject-small-div1 bg-white d-flex align-items-center p-3 shadow' style={{position:'absolute'}}>
                <img src={require('../../images/student/icon (1).png')} alt="" />
                <div className='ms-2'>
                  <p className='text-start fw-bold m-0'>2K+</p>
                  <p className='m-0'>Projects</p>
                </div>
              </div>

              <div className='subject-small-div2 bg-white d-flex align-items-center p-3 shadow' style={{position:'absolute'}}>
                <img src={require('../../images/page1/star.png')} alt="" />
                <div className='ms-2'>
                  <p className='text-start fw-bold m-0'>2K+</p>
                  <p className='m-0'>Projects</p>
                </div>
              </div>

              <div className='subject-small-div3 bg-white d-flex align-items-center p-3 shadow' style={{position:'absolute'}}>
                <img src={require('../../images/student/icon3.png')} alt="" />
                <div className='ms-2'>
                  <p className='text-start fw-bold m-0'>Product Designer</p>
                  <p className='m-0'>5 Years</p>
                </div>
              </div>
            </div>
        </div>

        <div className="row m-0 mx-auto container">
          <p className='main-text fs-3 mb-4'>OUR EXPERTISE</p>
       <div className="col-md-3">
       <div className="">
              <img src={require('../../images/student/Vector.png')} height={40} alt="" />
              <p className='fw-medium m-0 fs-5 mt-3'>Branding</p>
              <p className='text-secondary mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
        </div>
        <div className="col-md-3">
       <div className="">
              <img src={require('../../images/student/Ui Design.png')} height={40} alt="" />
              <p className='fw-medium m-0 fs-5 mt-3'>UI/UX</p>
              <p className='text-secondary mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
        </div>
        <div className="col-md-3">
       <div className="">
              <img src={require('../../images/student/Product.png')} height={40} alt="" />
              <p className='fw-medium m-0 fs-5 mt-3'>Product Design</p>
              <p className='text-secondary mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
        </div>
       </div>
      </div>

      {/* Layout2 */}
      <div className="row m-0 container mx-auto py-5 my-4 align-items-center">
        <div className="col-md-7 ps-md-5">
          <img src={require('../../images/student/Group 1171276064.png')} style={{width:'7  0%'}}  alt="" />
        </div>
        <div className="col-md-5 pe-md-5">
          <img src={require('../../images/student/fixed-width fixed-height sec-.png')} alt="" />
          <p className='fw-bolder fs-1 m-0'>Most Popular</p>
          <p className='fw-bolder fs-1' style={{lineHeight:'48px'}}>Courses</p>
          <p className='text-secondary'>Lorem ipsum dolor sit amet consectetur. At euismod non ultrices placerat augue nibh. Nibh sodales adipiscing quam quis. Pretium eget ac vitae neque vulputate accumsan elit cum consequat. Venenatis risus sed id enim orci magna ullamcorper. Mattis id tristique sed arcu commodo maecenas suspendisse. Quam senectus sem enim fringilla posuere morbi. Habitant sodales id platea a dictumst vestibulum imperdiet sit amet. Felis ut leo a duis morbi. Amet enim rhoncus sed faucibus gravida urna id diam duis.
          </p>
        </div>
      </div>

      {/* Layout3 */}
      <div className="row m-0 container mx-auto py-5 my-4 align-items-center">
        <div className="col-lg-6 pe-md-5 mb-5 mb-md-0">
        <p className='fw-bolder fs-1 m-0'>Best ways to send</p>
        <p className='fw-bolder fs-1' style={{lineHeight:'40px'}}>money internationally</p>
        <p className='text-secondary mt-4 pe-md-5'>Join over 12 million people who choose us for fast and secure online payment method . We are simply & cheaper than banks and non-banks.</p>
        <div className='mt-5'>
            <button className='btn fw-medium text-white px-3 py-2' style={{backgroundColor:'#205CE1',fontSize:'14px'}}>Join Now</button>
            <button className='btn fw-medium px-3 py-2 ms-3' style={{border:'1px solid #205CE1',fontSize:'14px',color:'#205ce1'}}>Learn More</button>
          </div>
        </div>
        <div className="col-lg-6 mt-md-5 mt-lg-0">
         <div className="row m-0 justify-content-center">
         <div className="col-md-6">
       <div className="">
              <img src={require('../../images/student/Icon.png')} height={40} alt="" />
              <p className='fw-medium m-0 fs-5 mt-3'>Over 15 million customers</p>
              <p className='text-secondary mt-2'>Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
              </div>
        </div>
        <div className="col-md-6">
       <div className="">
              <img src={require('../../images/student/Icon1.png')} height={40} alt="" />
              <p className='fw-medium m-0 fs-5 mt-3'>Licensed by Bank Wakanda</p>
              <p className='text-secondary mt-2'>Amet minim mollit non Exercitation veniam consequat sunt nostrud dummy content.</p>
              </div>
        </div>
         </div>
        </div>
      </div>

      {/* Layout4 */}
      <div className='py-5' style={{backgroundColor:'#f7f9ff'}}>
      <div className="row m-0 container mx-auto align-items-center">
<div className="col-md-7 text-center">
<img src={require('../../images/Rectangle 233.png')} style={{width:'60%'}} alt="" />
</div>
<div className="col-md-5">
  <p className='fs-1 fw-bold'>Make it dead easy for your customers to buy from you.</p>
  <p className='fw-bold'>Online payments are easier to process, give you more information online, and make processing faster.</p>
  <p className='text-secondary mt-4'>Payment with us is easy and straight forward, you can pay in dollars, euros and bitcoin or ether tokens.We accept all major currencies and cryptocurrencies</p>
</div>
      </div>
      </div>

      {/* Layout5 */}
      <div className="row m-0 container mx-auto py-5 align-items-center">
<div className="col-md-6 pe-md-5">
  <p className='fs-1 fw-bold' style={{lineHeight:'48px'}}>Coca Pay provides the best value for you</p>
  <p className='text-secondary mt-4'>We always ready to help by providing the best service in
  the filed of finance for you good finance provides a better life</p>

  <img src={require('../../images/page1/Group 114.png')} className='d-block mx-auto' style={{width:'80%'}} alt="" />
</div>

<div className="col-md-6 ps-md-5">
  <div className='d-flex align-items-start'>
    <img src={require('../../images/student/Logo.png')} alt="" />
    <div className='ms-4'>
      <p className='m-0 layout5-text'>Faster ways to make transactions</p>
      <p className='mt-3'>By accepting online payments you are making it dead easy for your customers to buy from you.</p>
    </div>
  </div>
  <div className='d-flex align-items-start mt-5'>
    <img src={require('../../images/student/Logo (1).png')} alt="" />
    <div className='ms-4'>
      <p className='m-0 layout5-text'>Payments can be made securely</p>
      <p className='mt-3'>Online payments are easier to process, give you more information online, and make processing faster.</p>
    </div>
  </div>
  <div className='d-flex align-items-start mt-5'>
    <img src={require('../../images/student/Logo (2).png')} alt="" />
    <div className='ms-4'>
      <p className='m-0 layout5-text'>Alternate sources of currency</p>
      <p className='mt-3'>If you don't have time for checkout lines, online payments make it easy to whip out your credit or debit card.</p>
    </div>
  </div>
</div>
      </div>

      {/* Layout6 */}
      <div style={{backgroundColor:'#EDEAFA'}} className='py-5'>
        <div className="row py-5 m-0 container mx-auto">
          <p className='fw-bold' style={{color:'#8236C8'}}>Quick examination</p>
          <p className='fs-1 fw-bold'>Approdable Packages</p>
          <p className='m-0' style={{color:'#737373'}}>Problems trying to resolve the conflict between</p>
          <p className='mb-5 pb-4' style={{color:'#737373'}}>the two major realms of Classical physics: Newtonian mechanics </p>

          <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
            <div className='packages-div px-2 px-md-5 py-4 shadow'>
              <div className='d-flex align-items-center' style={{height:'30%',position:'relative'}}>
              <p className="m-0" style={{zIndex:6}}><Subject_svg1/></p>
              <p className='m-0 package-icon' style={{position:'absolute',zIndex:1}}><Sunject_svg2/></p>
              </div>

              <p className='fw-bold m-0 mt-4'>Training Courses</p>
              <img src={require('../../images/student/fixed-width fixed-height sec-.png')} height={4} width={80} className='my-4' alt="" />
              <p>The gradual 
accumulation of 
information about </p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
            <div className='packages-div px-2 px-md-5 py-4 shadow'>
              <div className='d-flex align-items-center' style={{height:'30%',position:'relative'}}>
              <p className="m-0" style={{zIndex:6}}><Subject_svg3/></p>
              <p className='m-0 package-icon' style={{position:'absolute',zIndex:1}}><Sunject_svg2/></p>
              </div>

              <p className='fw-bold m-0 mt-4'>Training Courses</p>
              <img src={require('../../images/student/fixed-width fixed-height sec-.png')} height={4} width={80} className='my-4' alt="" />
              <p>The gradual 
accumulation of 
information about </p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
            <div className='packages-div px-2 px-md-5 py-4 shadow'>
              <div className='d-flex align-items-center' style={{height:'30%',position:'relative'}}>
              <p className="m-0" style={{zIndex:6}}><Subject_svg4/></p>
              <p className='m-0 package-icon' style={{position:'absolute',zIndex:1}}><Sunject_svg2/></p>
              </div>

              <p className='fw-bold m-0 mt-4'>Lifetime access</p>
              <img src={require('../../images/student/fixed-width fixed-height sec-.png')} height={4} width={80} className='my-4' alt="" />
              <p>The gradual 
accumulation of 
information about </p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
            <div className='packages-div px-2 px-md-5 py-4 shadow'>
              <div className='d-flex align-items-center' style={{height:'30%',position:'relative'}}>
              <p className="m-0" style={{zIndex:6}}><Subject_svg5/></p>
              <p className='m-0 package-icon' style={{position:'absolute',zIndex:1}}><Sunject_svg2/></p>
              </div>

              <p className='fw-bold m-0 mt-4'>Expert instruction</p>
              <img src={require('../../images/student/fixed-width fixed-height sec-.png')} height={4} width={80} className='my-4' alt="" />
              <p>The gradual 
accumulation of 
information about </p>
            </div>
          </div>

        </div>
      </div>

      {/* Layout7 */}
      <Blogs/>

      {/* Layout8 */}
      <Layout8/>

      {/* FAQ */}
      <FAQ/>

      <Main_footer/>
      <Backtotop/>
      <Scrolltotop/>
    </div>
  )
}

export default Subject_page
