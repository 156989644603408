import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Main_footer from '../Footer'
import Backtotop from '../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import { hostname } from '../../App'
import axios from 'axios'
import PopularBLog from '../Blog/PopularBLog'

const View_blog = () => {
  const navigate = useNavigate()
  let { slug } = useParams()
  let [blog, setBlog] = useState()
  let getParticularBlog = () => {
    axios.get(`${hostname}/data/${slug}`).then((response) => {
      console.log(response.data, 'blog');
      setBlog(response.data)
    }).catch((error) => {
      console.log(error);
    })
  }
  useEffect(() => {
    getParticularBlog()
  }, [])
  function formatDateToIST(isoDate) {
    const date = new Date(isoDate);

    // Convert to Indian Standard Time (IST)
    const options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      timeZone: 'Asia/Kolkata' // Set timezone to IST
    };
    return date.toLocaleDateString('en-IN', options);
  }
  return (
    <div className=''>
      <div className="container pt-5">
        <p className='d-flex align-items-center'><svg style={{ cursor: 'pointer' }} onClick={() => {
          navigate('/blogs')
        }} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
        </svg><span style={{ fontSize: '15px' }} className='fw-medium ms-2'>
            {blog && blog.Blog_Category && blog.Blog_Category.name ? blog.Blog_Category.name : "Travel"}  </span><span style={{ fontSize: '15px' }}
              className='text-secondary ms-4'>
            {blog && blog.created_at ? formatDateToIST(blog.created_at) : " 24 August 2024"}
          </span></p>
        <p className='fs-1 fw-bolder mt-4' style={{ lineHeight: '50px' }}>
          {blog && blog.Main_Title ? blog.Main_Title : "Train Or Bus Journey?Which one suits?"}
        </p>

        <div className='py-4'>
          <img src={blog && blog.img ? blog.img : require('../../images/blogs/Image Placeholder.png')}
            className='rounded w-full object-fit-cover ' style={{ width: '100%' }} alt="" />
        </div>

        <div className='mt-4'>
          <p className='mb-4' dangerouslySetInnerHTML={{ __html: blog && blog.Paragraph1 }} style={{ color: '#666666', fontSize: '15px' }}>

          </p>
          <p className='mb-4' dangerouslySetInnerHTML={{ __html: blog && blog.Paragraph2 }} style={{ color: '#666666', fontSize: '15px' }}>

          </p>


          {/* <div className='py-4 mb-4 text-center'>
            <img src={require('../../images/blogs/Image.png')} style={{ width: '80%' }} alt="" />
          </div> */}
          {
            blog && blog.Text_Content && blog.Text_Content.map((obj) => (
              <div className={`  `} >
                <h5 className='' dangerouslySetInnerHTML={{ __html: obj && obj.Heading }} ></h5>
                <p style={{ color: '#666666', fontSize: '15px' }} dangerouslySetInnerHTML={{ __html: obj && obj.Paragraph }} className={`  `} >

                </p>

              </div>
            ))
          }


        </div>

        <PopularBLog />

      </div>

      <Main_footer />
      <Backtotop />
      <Scrolltotop />
    </div>
  )
}

export default View_blog
