import React from 'react'
import { useNavigate } from 'react-router-dom'

const BlogCards = ({ data }) => {
    let navigate = useNavigate()
    console.log(data, 'blogcard');
    function formatDateToIST(isoDate) {
        const date = new Date(isoDate);

        // Convert to Indian Standard Time (IST)
        const options = {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            timeZone: 'Asia/Kolkata' // Set timezone to IST
        };
        return date.toLocaleDateString('en-IN', options);
    }

    return (
        <main className="col-lg-4 col-md-6 mb-4 pb-3">

            {data && <div class="card border-0">
                <img src={data.img ? data.img : require('../../images/blogs/Container.png')}
                    className="card-img-top blog-img object-fit-cover rounded " loading='lazy' alt="..." />
                <div class="card-body px-0 pt-4">
                    <p><span style={{ fontSize: '13px' }} className='fw-medium '>
                        {data.Blog_Category && data.Blog_Category.name ? data.Blog_Category.name : "Travel"}
                    </span><span style={{ fontSize: '13px' }} className='text-secondary ms-3'>
                            {data.created_at ? formatDateToIST(data.created_at) : " 24 August 2024"} </span></p>
                    <h5 dangerouslySetInnerHTML={{ __html: data.Main_Title && data.Main_Title }} className=" line-clamp-2 fw-bold" >

                    </h5>
                    <p className="card-text line-clamp-3 " dangerouslySetInnerHTML={{ __html: data.Paragraph1 && data.Paragraph1 }} style={{ fontSize: '14px', }}>

                    </p>
                    <a href="" class="fw-bold" onClick={() => {
                        navigate(`/blog/${data.slug}`)
                    }} style={{ color: '#7C4EE4' }}>
                        Read more...</a>
                </div>
            </div>}

        </main>
    )
}

export default BlogCards